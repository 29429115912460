import { useToken } from '@chakra-ui/react'
import React from 'react'
import {
  ReferenceArea,
  ReferenceAreaProps,
  XAxisProps,
  YAxisProps,
} from 'recharts'

type GoalRange = {
  min?: number | null
  max?: number | null
}

const GoalReferenceAreaShape: React.FC<React.SVGProps<SVGRectElement>> = (
  props,
) => {
  const goalColor = useToken('colors', 'success')
  const { x1, y1, x2, y2, x, y, width, height } = props

  const boundaryLines: Array<string | number> = []
  if (x1 !== undefined && height !== undefined) {
    boundaryLines.push(height)
  }
  if (y2 !== undefined) {
    boundaryLines.push(0)
  }

  const createVerticalLinePropsAt = (
    x: string | number,
  ): React.SVGProps<SVGLineElement> => ({
    x1: x,
    y1: 0,
    x2: x,
    y2: height,
  })

  const createHorizontalLinePropsAt = (
    y: string | number,
  ): React.SVGProps<SVGLineElement> => ({
    x1: 0,
    y1: y,
    x2: width,
    y2: y,
  })

  const boundaryLineProps: React.SVGProps<SVGLineElement>[] = []
  if (x1 !== undefined) {
    boundaryLineProps.push(createVerticalLinePropsAt(0))
  }
  if (x2 !== undefined && width !== undefined) {
    boundaryLineProps.push(createVerticalLinePropsAt(width))
  }
  if (y1 !== undefined && height !== undefined) {
    boundaryLineProps.push(createHorizontalLinePropsAt(height))
  }
  if (y2 !== undefined) {
    boundaryLineProps.push(createHorizontalLinePropsAt(0))
  }

  return (
    <g>
      <defs>
        {/* Generated here: https://svg-stripe-generator.web.app/ */}
        <pattern
          id="stripped_pattern"
          patternUnits="userSpaceOnUse"
          width="3"
          height="3"
          patternTransform="rotate(45)"
        >
          <line x1="0" y="0" x2="0" y2="3" stroke={goalColor} strokeWidth="1" />
        </pattern>
      </defs>
      <rect
        {...props}
        height={height}
        fill="url(#stripped_pattern)"
        fillOpacity={0.6}
      />
      <g transform={`translate(${x}, ${y})`}>
        {boundaryLineProps.map((line, i) => (
          <line key={i} stroke={goalColor} strokeWidth={1} {...line} />
        ))}
      </g>
    </g>
  )
}

type InjectedProps = {
  xAxisMap?: {
    [k: string]: XAxisProps
  }
  yAxisMap?: {
    [k: string]: YAxisProps
  }
  offset?: {
    top?: number
    bottom?: number
    left?: number
    right?: number
    width?: number
    height?: number
  }
}
export type GoalReferenceAreaProps = {
  goal: GoalRange | null
  goalAxis?: 'x' | 'y'
  style?: 'percent' | 'number'
} & Pick<ReferenceAreaProps, 'xAxisId' | 'yAxisId'> &
  InjectedProps

type BaseAxisProps = { domain?: XAxisProps['domain'] }

export const GoalReferenceArea: React.FC<GoalReferenceAreaProps> = ({
  goal,
  goalAxis = 'y',
  style = 'percent',
  xAxisId = 0,
  yAxisId = 0,
  ...injectedProps
}) => {
  const { xAxisMap, yAxisMap, offset } = injectedProps
  if (!offset) {
    return null
  }
  if (goal == null || (goal.min == null && goal.max == null)) {
    return null
  }

  const xAxis = xAxisMap?.[xAxisId] as ReferenceAreaProps['xAxis'] | undefined
  const yAxis = yAxisMap?.[yAxisId] as ReferenceAreaProps['yAxis'] | undefined

  const viewBox = {
    x: offset.left,
    y: offset.top,
    width: offset.width,
    height: offset.height,
  } as ReferenceAreaProps['viewBox']

  function getDomainMinMax(
    axis: BaseAxisProps | undefined,
  ): [number | undefined, number | undefined] {
    if (typeof axis?.domain === 'function') {
      throw new Error('GoalReferenceArea does not support function domain')
    }

    const domainNumberValues = (axis?.domain ?? []).filter(
      (x: unknown) => typeof x === 'number',
    ) as number[]
    const domainMin = Math.min(...domainNumberValues)
    const domainMax = Math.max(...domainNumberValues)
    return [
      Number.isFinite(domainMin) ? domainMin : undefined,
      Number.isFinite(domainMax) ? domainMax : undefined,
    ]
  }

  function normalizeValue(
    value: number,
    style: GoalReferenceAreaProps['style'],
  ): number {
    return style === 'percent' ? value / 100 : value
  }

  function getAxisMinMax(axis: BaseAxisProps | undefined): {
    min: number | undefined
    max: number | undefined
  } {
    if (goal == null) {
      return {
        min: undefined,
        max: undefined,
      }
    }
    const [domainMin, domainMax] = getDomainMinMax(axis)
    const goalMin =
      goal.min != null ? normalizeValue(goal.min, style) : undefined
    const goalMax =
      goal.max != null ? normalizeValue(goal.max, style) : undefined
    return {
      min:
        goalMin != null ? Math.max(domainMin ?? goalMin, goalMin) : undefined,
      max:
        goalMax != null ? Math.min(domainMax ?? goalMax, goalMax) : undefined,
    }
  }

  const xyProps: Pick<ReferenceAreaProps, 'x1' | 'x2' | 'y1' | 'y2'> = {
    x1: undefined,
    x2: undefined,
    y1: undefined,
    y2: undefined,
  }

  if (goalAxis === 'x') {
    const { min, max } = getAxisMinMax(xAxis)
    xyProps.x1 = min
    xyProps.x2 = max
  }

  if (goalAxis === 'y') {
    const { min, max } = getAxisMinMax(yAxis)
    xyProps.y1 = min
    xyProps.y2 = max
  }

  return (
    <ReferenceArea
      xAxis={xAxis}
      yAxis={yAxis}
      viewBox={viewBox}
      ifOverflow="extendDomain"
      shape={<GoalReferenceAreaShape />}
      {...xyProps}
    />
  )
}
